import React from 'react'
import App from 'App'
import styled from 'styled-components'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { SITE_LINK_PUBLISHING, CONTAINER_MAX_WIDTH } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import Footer from 'components/Footer'
import { Flex, Box, Grid } from 'components/Layout'
import Container from 'components/Container'
import imageHeader from 'static/images/integrations/snapchat/header_picture.webp'
import imageToolManage from 'static/images/integrations/snapchat/tools_to_manage.webp'
import imageText1 from 'static/images/integrations/snapchat/content.webp'
import imageText2 from 'static/images/integrations/snapchat/reports.webp'
import imageText3 from 'static/images/integrations/tiktok/image_text_3.webp'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import PageHeader from './components/PageHeader'
import ToolManageSection from './components/ToolManageSection'
import TextImageSection from './components/TextImageSection'
import CoreFeaturesSection from './components/CoreFeaturesSection'
import FaqSection from './components/FaqSection'
import GetStartedSection from '../GetStartedSection'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
`

const NETWORK = 'Snapchat'

const TEXT_IMAGES = [
  {
    header: {
      title: `Simplify ${NETWORK} Content Development & Profile Posting`,
      matches: [NETWORK],
    },
    textFirst: false,
    image: imageText1,
    items: [
      {
        title: 'Easily Create & Deliver Posts',
        description: `Draft posts for one or many ${NETWORK} Business Profiles — upload videos, attach images from a Media Library, apply targeting and add tags.`,
      },
      {
        title: 'Manage the Content Calendar',
        description: `Easily review and manage ${NETWORK} content from a central publishing calendar—quickly filter by tag, profile and date.`,
      },
      {
        title: 'Seamlessly With Approval Workflow',
        description:
          'Vista’s built-in approval workflow is perfect for legal, compliance, agency collaboration or peer review.',
      },
    ],
    linkText: 'MORE ON PUBLISHING',
    link: SITE_LINK_PUBLISHING,
  },
  {
    header: {
      title: `Schedule ${NETWORK} Stories and Spotlights With Trending Audio`,
      matches: ['Trending Audio'],
    },
    textFirst: false,
    headerMaxWidth: '100%',
    image: imageText3,
    items: [
      {
        title: 'Pick the best trending audio',
        description: `Choose your audio from a list of thoughtfully curated and maintained trending sounds.`,
      },
      {
        title: 'Upload your own sounds',
        description: `Easily upload sounds from ${NETWORK} or your own videos or sound files.`,
      },
      {
        title: 'Add audio options',
        description: 'Fully replace the audio in your video or add it as a background sound.',
      },
    ],
  },
  {
    header: {
      title: `Gather Strategic Insights with ${NETWORK} analytics`,
      matches: [NETWORK],
    },
    textFirst: true,
    image: imageText2,
    items: [
      {
        title: `Visualize ${NETWORK} Profile Data`,
        description:
          'Understand likes, shares, comments and views behavior with data visualizations and dynamic charts.',
      },
      {
        title: 'Identify Your Best Content',
        description: `Dig into ${NETWORK} Insights data and identify best performing content (engagement and viewing).`,
      },
      {
        title: 'Customized and Export Reports',
        description: 'Export insightful reports or customize and build your own to deliver to your stakeholders.',
      },
    ],
  },
]

const IntegrationsSocial = () => {
  return (
    <App fullHeader>
      <SEO
        title={`${NETWORK} management tools for business`}
        description={`Manage ${NETWORK} business profiles with features such as direct story and spotlight publishing and analytics. Vista Social is the world's first, fully approved by Snapchat, official Snapchat integration. No apps to install, no reminders to manage.`}
        path="/integrations/snapchat/"
      />

      <PageHeader
        network={NETWORK}
        headerText={`${NETWORK} management tools for business`}
        description={`Manage ${NETWORK} profiles with features such as direct story and spotlight publishing and analytics. Vista Social is the world's first, fully approved by Snapchat, official Snapchat integration. No apps to install, no reminders to manage.`}
        image={imageHeader}
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex mt="s" pt="s" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
            <G2FeaturesComponent />
          </Flex>
        </Container>
      </Wrapper>

      <ToolManageSection
        network={NETWORK}
        headerText={`Tools to Manage Multiple ${NETWORK} Business Profiles`}
        description={`Plan, schedule and publish your ${NETWORK} Stories and Spotlights`}
        image={imageToolManage}
        tools={[
          {
            title: `Create Engaging ${NETWORK} Content`,
            description: `Plan, create and publish engaging posts with a centralized content calendar, intuitive workflows and creative tools for ${NETWORK} publishing.`,
          },
          {
            title: `Edit Story and Spotlights Thumbnails and Audio`,
            description: `Ensure that your Snapchat Stories and Spotlights post with best possible thubmnail. Pick from the list of trending audio or upload your own.`,
          },

          {
            title: `Benchmark Your ${NETWORK} Performance`,
            description: `Gather insights for one or many ${NETWORK} Profiles to measure fan growth, analyze engagement and track post performance.`,
          },
        ]}
      />

      <TextImageSection textImages={TEXT_IMAGES} />

      <CoreFeaturesSection headerTitle={`Take your ${NETWORK} Marketing to the Next Level`} matches={[NETWORK]} />

      <FaqSection />

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default IntegrationsSocial
